import React, { useState, useEffect } from 'react';
import { Stack, Box, Flex, Heading, VStack, Text, Input, List, ListItem, useBreakpointValue, Button, useDisclosure, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';
import DestinationCard from '../components/Plans/DestinationCard';
import SearchBar from '../components/Plans/SearchBar';

import ApiClient from '../utils/API/api';
import countryUtils from '../utils/Plans/country';

import { filterAndSortForRegion, locationsWithOwnPlan } from '../utils/Plans/plans';

const PlansScreen = () => {
  const [data, setData] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [standaloneLocations, setStandaloneLocations] = useState([]);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState('');
  const [regionSelector, setRegionSelector] = useState([
    {
      name: 'Europe',
      code: 'EU',
      selected: false,
    },
    {
      name: 'North America',
      code: 'NA',
      selected: false,
    },
    {
      name: 'South America',
      code: 'SA',
      selected: false,
    },
    {
      name: 'Asia',
      code: 'AS',
      selected: false,
    },
    {
      name: 'Caribbean',
      code: 'CB',
      selected: false,
    },
    {
      name: 'Middle East',
      code: 'ME',
      selected: false,
    },
    {
      name: 'Africa',
      code: 'AF',
      selected: false,
    },
    // {
    //   name: 'Global',
    //   code: 'GL',
    //   selected: false,
    // },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => { return ""; });
      try {
        const response = await apiClient.get('/plans');
        setData(response.data);
        const standalone = locationsWithOwnPlan(response.data.obj.packageList)
        setStandaloneLocations(standalone);
        setDestinations(countryUtils.searchCountry("", standalone) ?? []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const regionSelectorTrigger = () => {
    // get selected region:
    const region = regionSelector.find((region) => region.selected);

    // If locality selector is selected, filter and sort by locality
    if (region && standaloneLocations) {
      setDestinations(
        countryUtils
          .getData()
          .filter(
            (country) =>
              country.region === region.name &&
              standaloneLocations.includes(country.code)
          )
      )
    } else {
      // If no region is selected, set destinations to all countries
      setDestinations(countryUtils.searchCountry("", standaloneLocations) ?? []);
    }
  }

  useEffect(() => {
    regionSelectorTrigger();
  }, [regionSelector]);

  useEffect(() => {
    if (searchQuery === '') {
      regionSelectorTrigger();
      return;
    }
    // Filter and sort by search query
    setDestinations(countryUtils.searchCountry(searchQuery, standaloneLocations) ?? []);
  }, [searchQuery])

  return (
    <Flex direction="column" p="5">
      <Flex justifyContent={isMobile ? 'space-around' : 'center'} alignItems="center" mb="5" >
        <SearchBar placeholder="Where do you need internet?" showIcon={!isMobile} width={isMobile ? '85%' : '65%'} setSearchQuery={setSearchQuery} />
        {isMobile && (
          <Button leftIcon={<FaFilter />} iconSpacing={"0"} onClick={onOpen}></Button>
        )}
      </Flex>

      <Flex flex="1" flexDirection={isMobile ? 'column' : 'row'} minH="100vh">
        {!isMobile && (
          <Box width="20%" p="5" bg="gray.20" height="100vh">
            <VStack align="start" spacing="5">
              <Box>
                <Heading size="md" mb="2">Regions</Heading>
                <List spacing={2}>
                  {regionSelector.map((plan) => (
                    <ListItem key={plan.name}>
                      <Button
                        variant={plan.selected ? 'solid' : 'ghost'}
                        minWidth="0"
                        borderRadius={'full'}
                        colorScheme={plan.selected ? 'purple' : 'gray'}
                        onClick={() => {
                          setRegionSelector(
                            regionSelector.map((item) => {
                              if (item.name === plan.name) {
                                // if selected, deselect
                                if (plan.selected) {
                                  return { ...item, selected: false };
                                }
                                return { ...item, selected: true };
                              }
                              return { ...item, selected: false };
                            })
                          );
                        }}
                      >
                        {plan.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </VStack>
          </Box>
        )}

        <Box flex="1" p="5" overflowY="auto">
          <Flex wrap="wrap" gap="2" justifyContent={"center"}>
            {destinations.map((destination) => {
              // select random landmark image from destination.landmarks
              let landmark;
              if (destination?.landmarks) {
                landmark = destination?.landmarks[Math.floor(Math.random() * destination?.landmarks.length)];
              } else {
                landmark = "";
              }
              return (
                <DestinationCard
                  key={destination.code}
                  code={destination.code}
                  country={destination.name}
                  price={destination.price}
                  landmarkUrl={process.env.PUBLIC_URL + '/assets/images/landmarks/' + landmark + '.png'}
                />
              )
            })}
          </Flex>
        </Box>
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>
            <DrawerBody>
              <VStack align="start" spacing="5">
                <Box>
                  <Heading size="md" mb="2">Continents</Heading>
                  <List spacing={2}>
                    <ListItem>Europe</ListItem>
                    <ListItem>Asia</ListItem>
                    <ListItem>North America</ListItem>
                    <ListItem>South America</ListItem>
                    <ListItem>Africa</ListItem>
                    <ListItem>Oceania</ListItem>
                  </List>
                </Box>
              </VStack>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default PlansScreen;
