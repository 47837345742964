const flagMapping = {
  "AD": '/assets/images/flags/ad.png',
  "AE": '/assets/images/flags/ae.png',
  "AF-EMIRATE": '/assets/images/flags/af-emirate.png',
  "AF": '/assets/images/flags/af.png',
  "AG": '/assets/images/flags/ag.png',
  "AI": '/assets/images/flags/ai.png',
  "AL": '/assets/images/flags/al.png',
  "AM": '/assets/images/flags/am.png',
  "AN": '/assets/images/flags/an.png',
  "AO": '/assets/images/flags/ao.png',
  "AQ-TRUE_SOUTH": '/assets/images/flags/aq-true_south.png',
  "AQ": '/assets/images/flags/aq.png',
  "AR": '/assets/images/flags/ar.png',
  "AS": '/assets/images/flags/as.png',
  "AT": '/assets/images/flags/at.png',
  "AU-ABORIGINAL": '/assets/images/flags/au-aboriginal.png',
  "AU-ACT": '/assets/images/flags/au-act.png',
  "AU-NSW": '/assets/images/flags/au-nsw.png',
  "AU-NT": '/assets/images/flags/au-nt.png',
  "AU-QLD": '/assets/images/flags/au-qld.png',
  "AU-SA": '/assets/images/flags/au-sa.png',
  "AU-TAS": '/assets/images/flags/au-tas.png',
  "AU-TORRES_STRAIT_ISLANDS": '/assets/images/flags/au-torres_strait_islands.png',
  "AU-VIC": '/assets/images/flags/au-vic.png',
  "AU-WA": '/assets/images/flags/au-wa.png',
  "AU": '/assets/images/flags/au.png',
  "AW": '/assets/images/flags/aw.png',
  "AX": '/assets/images/flags/ax.png',
  "AZ": '/assets/images/flags/az.png',
  "BA": '/assets/images/flags/ba.png',
  "BB": '/assets/images/flags/bb.png',
  "BD": '/assets/images/flags/bd.png',
  "BE": '/assets/images/flags/be.png',
  "BF": '/assets/images/flags/bf.png',
  "BG": '/assets/images/flags/bg.png',
  "BH": '/assets/images/flags/bh.png',
  "BI": '/assets/images/flags/bi.png',
  "BJ": '/assets/images/flags/bj.png',
  "BL": '/assets/images/flags/bl.png',
  "BM": '/assets/images/flags/bm.png',
  "BN": '/assets/images/flags/bn.png',
  "BO": '/assets/images/flags/bo.png',
  "BQ-BO": '/assets/images/flags/bq-bo.png',
  "BQ-SA": '/assets/images/flags/bq-sa.png',
  "BQ-SE": '/assets/images/flags/bq-se.png',
  "BR": '/assets/images/flags/br.png',
  "BS": '/assets/images/flags/bs.png',
  "BT": '/assets/images/flags/bt.png',
  "BW": '/assets/images/flags/bw.png',
  "BY": '/assets/images/flags/by.png',
  "BZ": '/assets/images/flags/bz.png',
  "CA-BC": '/assets/images/flags/ca-bc.png',
  "CA-QC": '/assets/images/flags/ca-qc.png',
  "CA": '/assets/images/flags/ca.png',
  "CC": '/assets/images/flags/cc.png',
  "CD": '/assets/images/flags/cd.png',
  "CF": '/assets/images/flags/cf.png',
  "CG": '/assets/images/flags/cg.png',
  "CH-GR": '/assets/images/flags/ch-gr.png',
  "CH": '/assets/images/flags/ch.png',
  "CI": '/assets/images/flags/ci.png',
  "CK": '/assets/images/flags/ck.png',
  "CL": '/assets/images/flags/cl.png',
  "CM": '/assets/images/flags/cm.png',
  "CN-XJ": '/assets/images/flags/cn-xj.png',
  "CN": '/assets/images/flags/cn.png',
  "CO": '/assets/images/flags/co.png',
  "CQ": '/assets/images/flags/cq.png',
  "CR": '/assets/images/flags/cr.png',
  "CU": '/assets/images/flags/cu.png',
  "CV": '/assets/images/flags/cv.png',
  "CW": '/assets/images/flags/cw.png',
  "CX": '/assets/images/flags/cx.png',
  "CY": '/assets/images/flags/cy.png',
  "CZ": '/assets/images/flags/cz.png',
  "DE": '/assets/images/flags/de.png',
  "DJ": '/assets/images/flags/dj.png',
  "DK": '/assets/images/flags/dk.png',
  "DM": '/assets/images/flags/dm.png',
  "DO": '/assets/images/flags/do.png',
  "DZ": '/assets/images/flags/dz.png',
  "EAST_AFRICAN_FEDERATION": '/assets/images/flags/east_african_federation.png',
  "EASTER_ISLAND": '/assets/images/flags/easter_island.png',
  "EC-W": '/assets/images/flags/ec-w.png',
  "EC": '/assets/images/flags/ec.png',
  "EE": '/assets/images/flags/ee.png',
  "EG": '/assets/images/flags/eg.png',
  "EH": '/assets/images/flags/eh.png',
  "ER": '/assets/images/flags/er.png',
  "ES-AR": '/assets/images/flags/es-ar.png',
  "ES-CE": '/assets/images/flags/es-ce.png',
  "ES-CN": '/assets/images/flags/es-cn.png',
  "ES-CT": '/assets/images/flags/es-ct.png',
  "ES-GA": '/assets/images/flags/es-ga.png',
  "ES-IB": '/assets/images/flags/es-ib.png',
  "ES-ML": '/assets/images/flags/es-ml.png',
  "ES-PV": '/assets/images/flags/es-pv.png',
  "ES-VARIANT": '/assets/images/flags/es-variant.png',
  "ES": '/assets/images/flags/es.png',
  "ET-AF": '/assets/images/flags/et-af.png',
  "ET-AM": '/assets/images/flags/et-am.png',
  "ET-BE": '/assets/images/flags/et-be.png',
  "ET-GA": '/assets/images/flags/et-ga.png',
  "ET-HA": '/assets/images/flags/et-ha.png',
  "ET-OR": '/assets/images/flags/et-or.png',
  "ET-SI": '/assets/images/flags/et-si.png',
  "ET-SN": '/assets/images/flags/et-sn.png',
  "ET-SO": '/assets/images/flags/et-so.png',
  "ET-SW": '/assets/images/flags/et-sw.png',
  "ET-TI": '/assets/images/flags/et-ti.png',
  "ET": '/assets/images/flags/et.png',
  "EU": '/assets/images/flags/eu.png',
  "EWE": '/assets/images/flags/ewe.png',
  "FI": '/assets/images/flags/fi.png',
  "FJ": '/assets/images/flags/fj.png',
  "FK": '/assets/images/flags/fk.png',
  "FM": '/assets/images/flags/fm.png',
  "FO": '/assets/images/flags/fo.png',
  "FR-20R": '/assets/images/flags/fr-20r.png',
  "FR-BRE": '/assets/images/flags/fr-bre.png',
  "FR-CP": '/assets/images/flags/fr-cp.png',
  "FR": '/assets/images/flags/fr.png',
  "GA": '/assets/images/flags/ga.png',
  "GB-CON": '/assets/images/flags/gb-con.png',
  "GB-ENG": '/assets/images/flags/gb-eng.png',
  "GB-NIR": '/assets/images/flags/gb-nir.png',
  "GB-ORK": '/assets/images/flags/gb-ork.png',
  "GB-SCT": '/assets/images/flags/gb-sct.png',
  "GB-WLS": '/assets/images/flags/gb-wls.png',
  "GB": '/assets/images/flags/gb.png',
  "GD": '/assets/images/flags/gd.png',
  "GE-AB": '/assets/images/flags/ge-ab.png',
  "GE": '/assets/images/flags/ge.png',
  "GF": '/assets/images/flags/gf.png',
  "GG": '/assets/images/flags/gg.png',
  "GH": '/assets/images/flags/gh.png',
  "GI": '/assets/images/flags/gi.png',
  "GL": '/assets/images/flags/gl.png',
  "GM": '/assets/images/flags/gm.png',
  "GN": '/assets/images/flags/gn.png',
  "GP": '/assets/images/flags/gp.png',
  "GQ": '/assets/images/flags/gq.png',
  "GR": '/assets/images/flags/gr.png',
  "GS": '/assets/images/flags/gs.png',
  "GT": '/assets/images/flags/gt.png',
  "GU": '/assets/images/flags/gu.png',
  "GUARANI": '/assets/images/flags/guarani.png',
  "GW": '/assets/images/flags/gw.png',
  "GY": '/assets/images/flags/gy.png',
  "HAUSA": '/assets/images/flags/hausa.png',
  "HK": '/assets/images/flags/hk.png',
  "HMONG": '/assets/images/flags/hmong.png',
  "HN": '/assets/images/flags/hn.png',
  "HR": '/assets/images/flags/hr.png',
  "HT": '/assets/images/flags/ht.png',
  "HU": '/assets/images/flags/hu.png',
  "ID-JB": '/assets/images/flags/id-jb.png',
  "ID-JT": '/assets/images/flags/id-jt.png',
  "ID": '/assets/images/flags/id.png',
  "IE": '/assets/images/flags/ie.png',
  "IL": '/assets/images/flags/il.png',
  "IM": '/assets/images/flags/im.png',
  "IN-AS": '/assets/images/flags/in-as.png',
  "IN-GJ": '/assets/images/flags/in-gj.png',
  "IN-KA": '/assets/images/flags/in-ka.png',
  "IN-MN": '/assets/images/flags/in-mn.png',
  "IN-MZ": '/assets/images/flags/in-mz.png',
  "IN-OR": '/assets/images/flags/in-or.png',
  "IN-TG": '/assets/images/flags/in-tg.png',
  "IN-TN": '/assets/images/flags/in-tn.png',
  "IN": '/assets/images/flags/in.png',
  "IO": '/assets/images/flags/io.png',
  "IQ-KR": '/assets/images/flags/iq-kr.png',
  "IQ": '/assets/images/flags/iq.png',
  "IR": '/assets/images/flags/ir.png',
  "IS": '/assets/images/flags/is.png',
  "IT-21": '/assets/images/flags/it-21.png',
  "IT-23": '/assets/images/flags/it-23.png',
  "IT-25": '/assets/images/flags/it-25.png',
  "IT-32": '/assets/images/flags/it-32.png',
  "IT-34": '/assets/images/flags/it-34.png',
  "IT-36": '/assets/images/flags/it-36.png',
  "IT-42": '/assets/images/flags/it-42.png',
  "IT-45": '/assets/images/flags/it-45.png',
  "IT-52": '/assets/images/flags/it-52.png',
  "IT-55": '/assets/images/flags/it-55.png',
  "IT-57": '/assets/images/flags/it-57.png',
  "IT-62": '/assets/images/flags/it-62.png',
  "IT-65": '/assets/images/flags/it-65.png',
  "IT-67": '/assets/images/flags/it-67.png',
  "IT-72": '/assets/images/flags/it-72.png',
  "IT-75": '/assets/images/flags/it-75.png',
  "IT-77": '/assets/images/flags/it-77.png',
  "IT-78": '/assets/images/flags/it-78.png',
  "IT-82": '/assets/images/flags/it-82.png',
  "IT-88": '/assets/images/flags/it-88.png',
  "IT": '/assets/images/flags/it.png',
  "JE": '/assets/images/flags/je.png',
  "JM": '/assets/images/flags/jm.png',
  "JO": '/assets/images/flags/jo.png',
  "JP": '/assets/images/flags/jp.png',
  "KANURI": '/assets/images/flags/kanuri.png',
  "KE": '/assets/images/flags/ke.png',
  "KG": '/assets/images/flags/kg.png',
  "KH": '/assets/images/flags/kh.png',
  "KI": '/assets/images/flags/ki.png',
  "KIKUYU": '/assets/images/flags/kikuyu.png',
  "KM": '/assets/images/flags/km.png',
  "KN": '/assets/images/flags/kn.png',
  "KONGO": '/assets/images/flags/kongo.png',
  "KP": '/assets/images/flags/kp.png',
  "KR": '/assets/images/flags/kr.png',
  "KW": '/assets/images/flags/kw.png',
  "KY": '/assets/images/flags/ky.png',
  "KZ": '/assets/images/flags/kz.png',
  "LA": '/assets/images/flags/la.png',
  "LB": '/assets/images/flags/lb.png',
  "LC": '/assets/images/flags/lc.png',
  "LI": '/assets/images/flags/li.png',
  "LK": '/assets/images/flags/lk.png',
  "LR": '/assets/images/flags/lr.png',
  "LS": '/assets/images/flags/ls.png',
  "LT": '/assets/images/flags/lt.png',
  "LU": '/assets/images/flags/lu.png',
  "LV": '/assets/images/flags/lv.png',
  "LY": '/assets/images/flags/ly.png',
  "MA": '/assets/images/flags/ma.png',
  "MALAYALI": '/assets/images/flags/malayali.png',
  "MAORI": '/assets/images/flags/maori.png',
  "MC": '/assets/images/flags/mc.png',
  "MD": '/assets/images/flags/md.png',
  "ME": '/assets/images/flags/me.png',
  "MF": '/assets/images/flags/mf.png',
  "MG": '/assets/images/flags/mg.png',
  "MH": '/assets/images/flags/mh.png',
  "MK": '/assets/images/flags/mk.png',
  "ML": '/assets/images/flags/ml.png',
  "MM": '/assets/images/flags/mm.png',
  "MN": '/assets/images/flags/mn.png',
  "MO": '/assets/images/flags/mo.png',
  "MP": '/assets/images/flags/mp.png',
  "MQ-OLD": '/assets/images/flags/mq-old.png',
  "MQ": '/assets/images/flags/mq.png',
  "MR": '/assets/images/flags/mr.png',
  "MS": '/assets/images/flags/ms.png',
  "MT": '/assets/images/flags/mt.png',
  "MU": '/assets/images/flags/mu.png',
  "MV": '/assets/images/flags/mv.png',
  "MW": '/assets/images/flags/mw.png',
  "MX": '/assets/images/flags/mx.png',
  "MY": '/assets/images/flags/my.png',
  "MZ": '/assets/images/flags/mz.png',
  "NA": '/assets/images/flags/na.png',
  "NC": '/assets/images/flags/nc.png',
  "NE": '/assets/images/flags/ne.png',
  "NF": '/assets/images/flags/nf.png',
  "NG": '/assets/images/flags/ng.png',
  "NI": '/assets/images/flags/ni.png',
  "NL-FR": '/assets/images/flags/nl-fr.png',
  "NL": '/assets/images/flags/nl.png',
  "NO": '/assets/images/flags/no.png',
  "NORTHERN_CYPRUS": '/assets/images/flags/northern_cyprus.png',
  "NP": '/assets/images/flags/np.png',
  "NR": '/assets/images/flags/nr.png',
  "NU": '/assets/images/flags/nu.png',
  "NZ": '/assets/images/flags/nz.png',
  "OCCITANIA": '/assets/images/flags/occitania.png',
  "OM": '/assets/images/flags/om.png',
  "OTOMI": '/assets/images/flags/otomi.png',
  "PA": '/assets/images/flags/pa.png',
  "PE": '/assets/images/flags/pe.png',
  "PF": '/assets/images/flags/pf.png',
  "PG": '/assets/images/flags/pg.png',
  "PH": '/assets/images/flags/ph.png',
  "PK-JK": '/assets/images/flags/pk-jk.png',
  "PK-SD": '/assets/images/flags/pk-sd.png',
  "PK": '/assets/images/flags/pk.png',
  "PL": '/assets/images/flags/pl.png',
  "PM": '/assets/images/flags/pm.png',
  "PN": '/assets/images/flags/pn.png',
  "PR": '/assets/images/flags/pr.png',
  "PS": '/assets/images/flags/ps.png',
  "PT-20": '/assets/images/flags/pt-20.png',
  "PT-30": '/assets/images/flags/pt-30.png',
  "PT": '/assets/images/flags/pt.png',
  "PW": '/assets/images/flags/pw.png',
  "PY": '/assets/images/flags/py.png',
  "QA": '/assets/images/flags/qa.png',
  "QUECHUA": '/assets/images/flags/quechua.png',
  "RE": '/assets/images/flags/re.png',
  "RO": '/assets/images/flags/ro.png',
  "RS": '/assets/images/flags/rs.png',
  "RU-BA": '/assets/images/flags/ru-ba.png',
  "RU-CE": '/assets/images/flags/ru-ce.png',
  "RU-CU": '/assets/images/flags/ru-cu.png',
  "RU-DA": '/assets/images/flags/ru-da.png',
  "RU-DPR": '/assets/images/flags/ru-dpr.png',
  "RU-KO": '/assets/images/flags/ru-ko.png',
  "RU-LPR": '/assets/images/flags/ru-lpr.png',
  "RU-TA": '/assets/images/flags/ru-ta.png',
  "RU-UD": '/assets/images/flags/ru-ud.png',
  "RU": '/assets/images/flags/ru.png',
  "RW": '/assets/images/flags/rw.png',
  "SA": '/assets/images/flags/sa.png',
  "SAMI": '/assets/images/flags/sami.png',
  "SB": '/assets/images/flags/sb.png',
  "SC": '/assets/images/flags/sc.png',
  "SD": '/assets/images/flags/sd.png',
  "SE": '/assets/images/flags/se.png',
  "SG": '/assets/images/flags/sg.png',
  "SH-AC": '/assets/images/flags/sh-ac.png',
  "SH-HL": '/assets/images/flags/sh-hl.png',
  "SH-TA": '/assets/images/flags/sh-ta.png',
  "SI": '/assets/images/flags/si.png',
  "SK": '/assets/images/flags/sk.png',
  "SL": '/assets/images/flags/sl.png',
  "SM": '/assets/images/flags/sm.png',
  "SN": '/assets/images/flags/sn.png',
  "SO": '/assets/images/flags/so.png',
  "SOMALILAND": '/assets/images/flags/somaliland.png',
  "SOUTH_OSSETIA": '/assets/images/flags/south_ossetia.png',
  "SOVIET_UNION": '/assets/images/flags/soviet_union.png',
  "SR": '/assets/images/flags/sr.png',
  "SS": '/assets/images/flags/ss.png',
  "ST": '/assets/images/flags/st.png',
  "SV": '/assets/images/flags/sv.png',
  "SX": '/assets/images/flags/sx.png',
  "SY": '/assets/images/flags/sy.png',
  "SZ": '/assets/images/flags/sz.png',
  "TC": '/assets/images/flags/tc.png',
  "TD": '/assets/images/flags/td.png',
  "TF": '/assets/images/flags/tf.png',
  "TG": '/assets/images/flags/tg.png',
  "TH": '/assets/images/flags/th.png',
  "TIBET": '/assets/images/flags/tibet.png',
  "TJ": '/assets/images/flags/tj.png',
  "TK": '/assets/images/flags/tk.png',
  "TL": '/assets/images/flags/tl.png',
  "TM": '/assets/images/flags/tm.png',
  "TN": '/assets/images/flags/tn.png',
  "TO": '/assets/images/flags/to.png',
  "TR": '/assets/images/flags/tr.png',
  "TRANSNISTRIA": '/assets/images/flags/transnistria.png',
  "TT": '/assets/images/flags/tt.png',
  "TV": '/assets/images/flags/tv.png',
  "TW": '/assets/images/flags/tw.png',
  "TZ": '/assets/images/flags/tz.png',
  "UA": '/assets/images/flags/ua.png',
  "UG": '/assets/images/flags/ug.png',
  "UK": '/assets/images/flags/gb.png',
  "US-AK": '/assets/images/flags/us-ak.png',
  "US-AL": '/assets/images/flags/us-al.png',
  "US-AR": '/assets/images/flags/us-ar.png',
  "US-AZ": '/assets/images/flags/us-az.png',
  "US-BETSY_ROSS": '/assets/images/flags/us-betsy_ross.png',
  "US-CA": '/assets/images/flags/us-ca.png',
  "US-CO": '/assets/images/flags/us-co.png',
  "US-CONFEDERATE_BATTLE": '/assets/images/flags/us-confederate_battle.png',
  "US-DC": '/assets/images/flags/us-dc.png',
  "US-FL": '/assets/images/flags/us-fl.png',
  "US-GA": '/assets/images/flags/us-ga.png',
  "US-HI": '/assets/images/flags/us-hi.png',
  "US-IN": '/assets/images/flags/us-in.png',
  "US-MD": '/assets/images/flags/us-md.png',
  "US-MO": '/assets/images/flags/us-mo.png',
  "US-MS": '/assets/images/flags/us-ms.png',
  "US-NC": '/assets/images/flags/us-nc.png',
  "US-NM": '/assets/images/flags/us-nm.png',
  "US-OR": '/assets/images/flags/us-or.png',
  "US-RI": '/assets/images/flags/us-ri.png',
  "US-SC": '/assets/images/flags/us-sc.png',
  "US-TN": '/assets/images/flags/us-tn.png',
  "US-TX": '/assets/images/flags/us-tx.png',
  "US-WA": '/assets/images/flags/us-wa.png',
  "US-WI": '/assets/images/flags/us-wi.png',
  "US-WY": '/assets/images/flags/us-wy.png',
  "US": '/assets/images/flags/us.png',
  "UY": '/assets/images/flags/uy.png',
  "UZ": '/assets/images/flags/uz.png',
  "VA": '/assets/images/flags/va.png',
  "VC": '/assets/images/flags/vc.png',
  "VE": '/assets/images/flags/ve.png',
  "VG": '/assets/images/flags/vg.png',
  "VI": '/assets/images/flags/vi.png',
  "VN": '/assets/images/flags/vn.png',
  "VU": '/assets/images/flags/vu.png',
  "WF": '/assets/images/flags/wf.png',
  "WIPHALA": '/assets/images/flags/wiphala.png',
  "WS": '/assets/images/flags/ws.png',
  "XK": '/assets/images/flags/xk.png',
  "XX": '/assets/images/flags/xx.png',
  "YE": '/assets/images/flags/ye.png',
  "YORUBALAND": '/assets/images/flags/yorubaland.png',
  "YT": '/assets/images/flags/yt.png',
  "YU": '/assets/images/flags/yu.png',
  "ZA": '/assets/images/flags/za.png',
  "ZM": '/assets/images/flags/zm.png',
  "ZW": '/assets/images/flags/zw.png'
};

// Export the flag mapping
export default flagMapping;