import React from 'react';
import { Box, Flex, Heading, Text, Image, Icon } from '@chakra-ui/react';
import { MdOutlinePhonelinkSetup, MdModeOfTravel, MdDataUsage, MdCardGiftcard, MdHeadsetMic } from "react-icons/md";
import { TbHandClick } from "react-icons/tb";



const FeatureSection = () => (
  <Box bg="gray.50" py="20">
    <Flex direction="column" align="center" maxW="1200px" mx="auto">
      <Heading size="xl" p="5" mb="10">Why Choose Nesa Mobile?</Heading>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdOutlinePhonelinkSetup} w={50} h={50} />
          <Heading size="md" mt="4">Effortless Activation</Heading>
          <Text mt="2">Activate your eSIM in minutes with just a few taps. No physical SIM cards needed.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={TbHandClick} w={50} h={50} />
          <Heading size="md" mt="4">Global Coverage</Heading>
          <Text mt="2">Stay connected in 185+ countries. From bustling cities to remote getaways, we've got you covered.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdModeOfTravel} w={50} h={50} />
          <Heading size="md" mt="4">Flexible Plans</Heading>
          <Text mt="2">Choose from a variety of data plans that fit your journey and budget. Pay only for what you need.</Text>
        </Box>
      </Flex>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdDataUsage} w={50} h={50} />
          <Heading size="md" mt="4">Real-Time Data Tracking</Heading>
          <Text mt="2">Monitor your data usage in real-time and receive alerts before you run out.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdCardGiftcard} w={50} h={50} />
          <Heading size="md" mt="4">Exclusive Perks</Heading>
          <Text mt="2">Earn NesaPoints with every purchase and redeem them for discounts on future adventures.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdHeadsetMic} w={50} h={50} />
          <Heading size="md" mt="4">24/7 Support</Heading>
          <Text mt="2">Our dedicated support team is here to help you anytime, anywhere.</Text>
        </Box>
      </Flex>
    </Flex>
  </Box>
);

export default FeatureSection;
