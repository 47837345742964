import React from 'react';
import { Container, Heading, Text, VStack, Link, List, ListItem, ListIcon } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

function TermsOfService() {
  return (
    <Container maxW="container.md" py={5}>
      <VStack spacing={4} align="start">
        {/* <Heading as="h1" size="xl">Terms of Service</Heading> */}

        <Heading as="h2" size="lg">1. General Terms and Conditions</Heading>
        <Text>
          These Terms of Service govern all use of Nesa Mobile services, including the purchase and use of eSIMs through our website and mobile application. Acceptance of these terms is mandatory for using our services. Nesa Mobile reserves the right to update or modify these terms at any time.
        </Text>

        <Heading as="h2" size="lg">2. Description of Services</Heading>
        <List spacing={2}>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="green.500" />
            <strong>eSIM Provisioning:</strong> Nesa Mobile offers prepaid eSIM services accessible through our platform. Payments are accepted via major credit cards and digital payment methods including PayPal and Stripe.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="green.500" />
            <strong>Registration Requirements:</strong> Customers must provide accurate personal information during the registration process and agree to the terms to use the services.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="green.500" />
            <strong>Service Quality:</strong> Nesa Mobile strives to provide uninterrupted service but does not guarantee that the services will be free from errors or interruptions.
          </ListItem>
        </List>

        <Heading as="h2" size="lg">3. User Responsibilities</Heading>
        <Text>
          Users must not engage in illegal, abusive, or fraudulent activities using the services. Nesa Mobile reserves the right to suspend services if a user violates these terms, with continued obligation for payment during suspension.
        </Text>

        <Heading as="h2" size="lg">4. Payment Terms</Heading>
        <Text>
          Payments must be made in U.S. Dollars. Disputed charges should be reported within 14 days. Users are not entitled to offset charges unless claims are undisputed or confirmed by a court judgment.
        </Text>

        <Heading as="h2" size="lg">5. Term, Termination, and Suspension</Heading>
        <Text>
          The contract for services commences upon purchase and continues until terminated by either party. Services may be terminated by Nesa Mobile if a user fails to comply with the terms.
        </Text>

        <Heading as="h2" size="lg">6. Refunds and Cancellations</Heading>
        <Text>
          Users can request refunds or replacements for non-activated eSIMs due to technical issues attributable to Nesa Mobile. Terms for cancellation are specified in our Cancellation Policy available on our website.
        </Text>

        <Heading as="h2" size="lg">7. Privacy Policy</Heading>
        <Text>
          Our Privacy Policy, available on our website, explains how we handle personal information collected through our services.
        </Text>

        <Heading as="h2" size="lg">8. Limitations of Liability</Heading>
        <Text>
          Nesa Mobile is not liable for losses incurred due to unavoidable service interruptions or failures. Users are advised to review the limitations outlined in this section.
        </Text>

        <Heading as="h2" size="lg">9. Governing Law</Heading>
        <Text>
          These Terms are governed by the laws of Canada and disputes subject to resolution in Canadian courts.
        </Text>

        <Heading as="h2" size="lg">10. Changes to Terms</Heading>
        <Text>
          We reserve the right to make changes to these terms and will notify users of any significant changes through our communication channels.
        </Text>

      </VStack>
    </Container>
  );
}

export default TermsOfService;
