import React from 'react';
import { Flex, Button, Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';

const FAQSection = () => {

  const navigate = useNavigate();

  return (
    <Box py="20">
      <Box maxW="1200px" mx="auto">
        <Heading size="xl" mb="10" textAlign="center">Got Questions? We've Got Answers.</Heading>
        <Accordion allowMultiple>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Do I have to activate data roaming on my device?</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb="4">
              Yes, you need to activate data roaming on the Nesa eSIM for it to work properly. Don't worry your main line does not need to have data roaming enabled.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">When do the days of my data plan start counting?</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb="4">
              The days start counting from the moment the eSIM is activated and you land in the destination country.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Is Nesa Compatible with My Device?</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb="4">
              Most modern smartphones support eSIMs, including the latest iPhones and Android devices. Check our compatibility list
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Can I Use Multiple eSIMs on One Device?</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb="4">
              Absolutely! You can have multiple eSIMs on your device, perfect for juggling different plans or staying connected with family and friends.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">What Happens If I Run Out of Data?</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb="4">
              You can easily top up your data plan directly from the app without any interruptions to your connectivity.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Why is it data-only eSIMs?</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb="4">
              Most carriers require additional ID documentation and verification to assign a local phone number due to regulatory and security reasons. To streamline the process and enhance your experience, we offer data-only eSIMs that don't require these additional steps.
            </AccordionPanel>
          </AccordionItem>
          {/* Add more FAQs here */}
        </Accordion>
        <Flex wrap="wrap" justify="center" w="100%">
          <Button colorScheme="purple" size="lg" mt="10" onClick={() => { window.location.href = 'https://help.nesamobile.com' }}>I Have More Questions</Button>
        </Flex>


      </Box>
    </Box>
  )
};

export default FAQSection;
