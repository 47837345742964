import React, { useState } from 'react';
import {
  Box,
  Container,
  Flex,
  Heading,
  VStack,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Icon
} from '@chakra-ui/react';
import { RxHamburgerMenu } from "react-icons/rx";
import { MdInfo, MdAccountCircle, MdBuild, MdHelp, MdPayment, MdBusiness } from 'react-icons/md';

import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'

const faqs = require('../utils/FAQs.json'); // Make sure your JSON is structured with ids

function FAQ() {
  const [selectedCategory, setSelectedCategory] = useState(faqs.sections[0].id);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const generateIcons = (categoryId) => {
    const category = faqs.sections.find(section => section.id === categoryId)?.title;
    switch (category) {
      case "General Questions":
        return <Icon as={MdInfo} boxSize={25} />;
      case "About":
        return <Icon as={MdAccountCircle} boxSize={25} />;
      case "Troubleshooting":
        return <Icon as={MdBuild} boxSize={25} />;
      case "Account & Billing":
        return <Icon as={MdPayment} boxSize={25} />;
      case "Getting Started":
        return <Icon as={MdHelp} boxSize={25} />;
      case "Partnerships":
        return <Icon as={MdBusiness} boxSize={25} />;
      default:
        return null;
    }
  };

  return (
    <Container maxW="container.xl" p={4} minH={"100vh"}>
      <Flex direction={{ base: "column", md: "row" }}>
        {isMobile ? (
          <>
            <Button rightIcon={<RxHamburgerMenu />} variant='outline' onClick={onOpen}>
              See Categories
            </Button>
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Categories</DrawerHeader>
                <DrawerBody>
                  <VStack align="start" spacing={4}>
                    {faqs.sections.map((section) => (
                      <Button
                        key={section.id}
                        variant="ghost"
                        fontWeight={selectedCategory === section.id ? "bold" : "normal"}
                        onClick={() => {
                          setSelectedCategory(section.id);
                          if (isMobile) onClose();
                        }}
                        w="full"
                      >
                        {section.title}
                      </Button>
                    ))}
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        ) : (
          <Box minW="200px" mr={10}>
            <VStack align="start" spacing={4}>
              {faqs.sections.map((section) => (
                <Button
                  key={section.id}
                  variant="ghost"
                  fontWeight={selectedCategory === section.id ? "bold" : "normal"}
                  onClick={() => setSelectedCategory(section.id)}
                  w="full"
                >
                  {section.title}
                </Button>
              ))}
            </VStack>
          </Box>
        )}
        <Box flex="1" bg="white" p={5} borderRadius="md" borderWidth="1px">
          <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
            {generateIcons(selectedCategory)}
            <Heading size={"lg"}>{faqs.sections.find(section => section.id === selectedCategory)?.title}</Heading>
          </Flex>

          <VStack align="start" spacing={4} mt={4}>
            {faqs.sections.find(section => section.id === selectedCategory)?.questions.map((faq) => (
              <ReactRouterLink style={{ textDecoration: 'underline' }} to={`/faq/q/${faq.id}`} key={faq.id}>
                {faq.question}
              </ReactRouterLink>
            ))}
          </VStack>
        </Box>
      </Flex>
    </Container>
  );
}

export default FAQ;
