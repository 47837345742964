import Fuse from 'fuse.js'
const data = require('./countries.json');
const regional = require('./regional.json');


/** Precompute name and code lookups. */
var nameMap = {}
var codeMap = {}
data.forEach(mapCodeAndName)

function mapCodeAndName(country) {
  nameMap[country.name.toLowerCase()] = country.code
  codeMap[country.code.toLowerCase()] = country.name
}

const omittedCountryCodes = ["AD", "AG", "AI", "AO", "AQ", "AS", "AW", "BB", "BI", "BJ", "BL", "BM", "BQ", "BS", "BV", "BZ", "CC", "CK", "CU", "CV", "CW", "CX", "DJ", "DM", "EH", "ER", "ET", "FK", "FM", "GD", "GF", "GM", "GN", "GQ", "GS", "GY", "HM", "HT", "IO", "IQ", "JM", "KM", "KN", "KP", "KY", "LB", "LC", "LS", "LY", "MF", "MH", "MM", "MP", "MR", "MS", "NA", "NC", "NF", "NU", "PF", "PM", "PN", "PW", "RW", "SH", "SL", "SO", "SR", "SS", "ST", "SX", "SY", "TC", "TF", "TG", "TK", "TL", "TM", "TT", "TV", "UM", "VC", "VE", "VI", "WF", "YT"];

const countryUtils = {};

countryUtils.overwrite = function overwrite(countries) {
  if (!countries || !countries.length) return
  countries.forEach(function (country) {
    var foundIndex = data.findIndex(function (item) {
      return item.code === country.code
    })
    data[foundIndex] = country
    mapCodeAndName(country)
  })
}

countryUtils.getCode = function getCode(name) {
  return nameMap[name.toLowerCase()]
}

countryUtils.getName = function getName(code) {
  return codeMap[code.toLowerCase()]
}

countryUtils.getNames = function getNames() {
  return data.map(function (country) {
    return country.name
  })
}

countryUtils.getCodes = function getCodes() {
  return data.map(function (country) {
    return country.code
  })
}

countryUtils.getCodeList = function getCodeList() {
  return codeMap
}

countryUtils.getNameList = function getNameList() {
  return nameMap
}

countryUtils.getData = function getData() {
  return data
}


countryUtils.sortCountriesByName = function sortCountriesByName(countries) {
  return countries.sort((a, b) => a.name.localeCompare(b.name))
}

countryUtils.getSortedCountriesFromCode = function getSortedCountriesFromCode(codes) {
  // Sort countries names by array of codes provided and then sort by name alphabetically
  return this.sortCountriesByName(data.filter(country => codes.includes(country.code)))
}

countryUtils.searchCountry = function searchCountry(input, onlyLocations = []) {

  const lowerInput = input.toLowerCase()
  const code = nameMap[lowerInput] || input
  const name = codeMap[lowerInput] || input

  // Filter out omitted countries
  let filteredData = data.filter(function (country) {
    return !omittedCountryCodes.includes(country.code)
  });

  // Additionally only include countries from the provided list onlyLocations
  if (onlyLocations.length > 0) {
    filteredData = filteredData.filter(function (country) {
      return onlyLocations.includes(country.code)
    })
  }

  // If empty return all countries:
  if (!input) return this.sortCountriesByName(filteredData);

  // Direct match search
  const directMatches = filteredData.filter(function (country) {
    return country.code.toLowerCase() === code.toLowerCase() || country.name.toLowerCase().includes(name.toLowerCase())
  })

  if (directMatches.length > 0) return directMatches

  // Fuzzy search
  console.log(Fuse); // Check the Fuse constructor status

  const fuse = new Fuse(filteredData, {
    keys: ['name', 'code'],
    threshold: 0.3 // Adjust threshold as needed
  })

  const results = fuse.search(input)
  console.log(results);

  if (results.length === 0) {
    return []; // Return an empty array directly if no results
  }

  return this.sortCountriesByName(results.map(result => result.item))
}

countryUtils.getRegional = function getRegional() {
  const filteredRegional = regional.filter(function (region) {
    return region.countries < 100
  });
  return filteredRegional;
}

countryUtils.getGlobal = function getGlobal() {
  const filteredRegional = regional.filter(function (region) {
    return region.countries >= 100
  });
  return filteredRegional;
}

countryUtils.getCountriesFromArray = function getCountriesFromArray(codes) {
  return data.filter(country => codes.includes(country.code));
}

countryUtils.shuffleArray = function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

export default countryUtils;