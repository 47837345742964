import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  SimpleGrid,
  Icon,
  List,
  ListItem,
  ListIcon,
  AspectRatio,
  Flex
} from "@chakra-ui/react";
import { FaGlobe, FaSimCard, FaDownload } from "react-icons/fa";
import { motion } from "framer-motion";

import CountryFlag from "../components/Plans/CountryFlag";
import countryUtils from "../utils/Plans/country";

const LandingPage = () => {
  const MotionBox = motion(Box);

  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  const europePlanCountries = [
    "Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czechia", "Denmark",
    "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Iceland", "Ireland",
    "Italy", "Latvia", "Liechtenstein", "Lithuania", "Luxembourg", "Netherlands", "Norway",
    "Poland", "Portugal", "Romania", "Slovakia", "Slovenia", "Spain", "Sweden",
    "Switzerland", "Turkey", "Ukraine", "United Kingdom"
  ];

  const individualCountries = [
    "Australia",
    "China",
    "Hong Kong",
    "Indonesia",
    "Japan",
    "Macao",
    "Malaysia",
    "Singapore",
    "South Korea",
    "Thailand",
    "United States"
  ];

  return (
    <Box fontFamily="sans-serif" bg="#521262" color="white">
      {/* Hero Section */}
      <Box py={10} textAlign="center">
        <Heading fontSize={{ base: "2xl", md: "4xl" }}>
          Get 1GB of Free Data – Instantly!
        </Heading>
        <Text mt={4} fontSize={{ base: "sm", md: "md" }}>
          Experience seamless connectivity with Nesa Mobile. Use coupon <b>FREE1GB</b> to get your free eSIM today.
        </Text>
        <VStack mt={6} spacing={4}>
          <Button size="lg" bg="white" color="#521262" onClick={() => scrollTo("how-it-works")}>
            Claim Now
          </Button>
          <Button size="lg" bg="white" color="#521262" onClick={() => scrollTo("video")}>
            Watch Video
          </Button>
        </VStack>
      </Box>

      {/* How It Works */}
      <Box id="how-it-works" py={10} textAlign="center">
        <Heading mb={6}>How It Works</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} px={6}>
          {[
            {
              title: "Download the Nesa Mobile App",
              icon: FaDownload,
              text: <Text>
                <Text>Available on iOS and Android.</Text>
                <Button size="sm" colorScheme="white" color="white" mt={2} onClick={() => {
                  window.open("https://nx.ms/download", "_blank");
                }}> Download Now </Button>
              </Text>,
            },
            {
              title: "Claim Your Free 1GB eSIM",
              icon: FaSimCard,
              text: <Text>Use coupon <Text style={{ display: 'inline', padding: 2, backgroundColor: 'black', color: 'white' }}>FREE1GB</Text> to activate your free plan.</Text>,
            },
            {
              title: "Start Browsing",
              icon: FaGlobe,
              text: <Text>Enjoy seamless connectivity across supported countries.</Text>,
            },
          ].map((step, index) => (
            <MotionBox
              key={index}
              p={6}
              bg="white"
              borderRadius="md"
              shadow="md"
              color="#521262"
              whileHover={{ scale: 1.05 }}
              transition="0.3s"
            >
              <Icon as={step.icon} w={12} h={12} mb={4} />
              <Heading fontSize="lg" mb={2}>
                {step.title}
              </Heading>
              {step.text}
            </MotionBox>
          ))}
        </SimpleGrid>
      </Box>

      {/* Video Walkthrough */}
      <Box id="video" py={10} textAlign="center">
        <Heading mb={6}>Watch How Easy It Is</Heading>
        <AspectRatio ratio={16 / 9} maxW="800px" mx="auto">
          <iframe
            title="Nesa Mobile Walkthrough"
            src="https://www.youtube.com/embed/z_LgZO8cqic"
            allowFullScreen
          />
        </AspectRatio>
        <Text mt={4} fontSize="sm" color="gray.300">
          See how you can get started in less than 2 minutes!
        </Text>
      </Box>

      {/* Supported Countries */}
      <Box py={10} mx={5} textAlign="center" bg="#4B0D57">
        <Heading mb={6}>Supported Countries</Heading>
        <Text mb={4} fontSize="lg">Explore our individual and Europe plan coverage:</Text>
        <Text mb={8}>
          Enjoy free data in 44 destinations! Opt for our Europe plan for extensive regional coverage, or choose an individual plan for destinations outside of Europe.
        </Text>

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} px={6} textAlign="left">
          <Box>
            <Heading fontSize="md" mb={4}>
              Europe Plan (1GB / 30 Days)
            </Heading>
            <List spacing={2}>
              {europePlanCountries.map((country, index) => (
                <ListItem key={index} stty>
                  <Flex alignItems="center" gap={2}>
                    <CountryFlag isoCode={countryUtils.getCode(country)} size={25} />
                    <Text fontSize="lg">{country}</Text>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Heading fontSize="md" mb={4}>
              Individual Plans (1GB / 7 Days)
            </Heading>
            <List spacing={2}>
              {individualCountries.map((country, index) => (
                <ListItem key={index} stty>
                  <Flex alignItems="center" gap={2}>
                    <CountryFlag isoCode={countryUtils.getCode(country)} size={25} />
                    <Text fontSize="lg">{country}</Text>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default LandingPage;
