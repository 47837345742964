import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Link,
  Image,
  SimpleGrid,
  Icon,
  Flex,
  Grid,
  Button
} from '@chakra-ui/react';
import { FaEnvelope, FaPhone, FaCheckCircle, FaClock, FaCogs, FaComments, FaStopwatch } from 'react-icons/fa';

import { Link as ReactRouterLink } from 'react-router-dom'

function AboutUs() {
  return (
    <Container maxW="container.xl" py={10} minH={"100vh"}>
      <VStack spacing={10} align="start">

        {/* About Us Section */}
        <HStack spacing={5} w="full" align="start">
          <Box flex="1">
            <Heading size="xl">About Nesa</Heading>
            <Text fontSize="lg" mt={3}>
              At Nesa, we’re dedicated to transforming the way you stay connected. Driven by innovation and a passion for simplifying communication, our eSIM solutions ensure you're always just a click away from home, no matter where you are in the world.
            </Text>
          </Box>
          <Image src="https://via.placeholder.com/400" alt="About Us Image" borderRadius="md" display={{ base: 'none', md: 'block' }} // This line makes the image disappear on smaller screens
          />
        </HStack>

        {/* Why Choose Us Section */}
        <Box w="full">
          <Heading size="xl" textAlign={"center"}>Why Choose Us?</Heading>
          <Text mt={4} textAlign={"center"}>
            Our mission is to provide seamless, reliable, and affordable global connectivity. <br /> We believe in creating solutions that empower travelers and digital nomads to explore the world with confidence and ease.
          </Text>
          <Flex mt={5} wrap="wrap" justify="space-between">
            <VStack spacing={4} align="start" flexBasis="30%">
              <Icon as={FaCheckCircle} w={8} h={8} />
              <Box>
                <Heading size="md">Fresh Perspective</Heading>
                <Text>We bring new, agile approaches to eSIM technology, designed for modern travelers.</Text>
              </Box>
            </VStack>
            <VStack spacing={4} align="start" flexBasis="30%">
              <Icon as={FaStopwatch} w={8} h={8} />
              <Box>
                <Heading size="md">Rapid Service</Heading>
                <Text>Experience quick and seamless eSIM provisioning and activation with minimal fuss.</Text>
              </Box>
            </VStack>
            <VStack spacing={4} align="start" flexBasis="30%">
              <Icon as={FaComments} w={8} h={8} />
              <Box>
                <Heading size="md">24/7 Support</Heading>
                <Text>Our dedicated team is always ready to assist you, ensuring you're never left disconnected.</Text>
              </Box>
            </VStack>
          </Flex>
        </Box>

        {/* Our Expertise Section */}
        {/* <Box w="full">
          <Heading size="xl" mb={10}>Our Expertise</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <VStack spacing={4} align="start">
              <Icon as={FaCogs} w={8} h={8} />
              <Box>
                <Heading size="md">Innovation</Heading>
                <Text>Utilizing the latest technology to ensure efficient service delivery.</Text>
              </Box>
            </VStack>
            <VStack spacing={4} align="start">
              <Icon as={FaClock} w={8} h={8} />
              <Box>
                <Heading size="md">Timeliness</Heading>
                <Text>Ensuring prompt delivery of services and support.</Text>
              </Box>
            </VStack>
            <VStack spacing={4} align="start">
              <Icon as={FaCheckCircle} w={8} h={8} />
              <Box>
                <Heading size="md">Quality</Heading>
                <Text>Adhering to high standards to meet customer expectations.</Text>
              </Box>
            </VStack>
          </SimpleGrid>
        </Box> */}

        {/* Contact Section */}
        <VStack spacing={5} py={10} w="full" justify="center" textAlign={"center"}>
          <Box flex="1">
            <Heading size="xl">Contact Us</Heading>
            <Text fontSize="lg" mt={3}>
              We're always here to help! For any questions or support, feel free to reach out to us!
            </Text>
            <Button variant="solid" as={ReactRouterLink} colorScheme="purple" mt={5} to={'/contact'}>Contact Us</Button>
          </Box>
        </VStack>

      </VStack>
    </Container>
  );
}

export default AboutUs;
