import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  SimpleGrid,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure
} from '@chakra-ui/react';
import { FaPhone, FaCommentDots, FaNewspaper, FaBriefcase } from 'react-icons/fa';

import { Link } from 'react-router-dom'

function Contact() {
  const bg = useColorModeValue('gray.800', 'gray.800');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalDetails, setModalDetails] = useState({
    title: '',
    description: <Text>Hello world</Text>,
  });

  return (
    <Container maxW="container.xl" py={10} centerContent>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalDetails.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {modalDetails.description}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box p={5} w="full" rounded="md">
        <Box p={5} rounded={"md"}>
          <Heading as="h1" size="xl" textAlign="center">Check out our FAQ</Heading>
          <Text textAlign="center" mt={2}>Your questions might already be answered on our Frequently Asked Questions page! Why not check it out?</Text>
          <Flex justifyContent="center" mt={5}>
            <Button as={Link} to={'/help'} variant="solid" colorScheme="purple" mt={5}>Visit FAQ</Button>
          </Flex>
        </Box>
        <Box p={5} rounded="md">
          <Heading as="h1" size="xl" textAlign="center">Get in touch</Heading>
          <Text textAlign="center" mt={2}>Still need some help? Choose one of the following options for support:</Text>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mt={10}>
            <Box p={5} bg={bg} color={"white"} boxShadow='xl' rounded='md'>
              <Flex alignItems="center" flexDirection="column">
                <FaCommentDots size="3em" />
                <Heading as="h4" size="md" mt={4}>Customer Support</Heading>
                <Text textAlign="center">Get help with your eSIM needs.</Text>
                <Button mt={4} variant="solid" leftIcon={<FaCommentDots />}
                  onClick={() => {
                    setModalDetails({
                      title: 'Customer Support',
                      description: <Text>
                        Our customer support team is available 24/7 to help you with any questions or concerns you may have. Here are some ways to get in touch with us:<br /><br />
                        <br />
                        Email: <a href="mailto:help@nesamobile.com" style={{ textDecoration: 'underline' }}>help@nesamobile.com</a><br />
                        WhatsApp: <a target='_blank' href="https://wa.me/message/Z6CYYQEBRG5XM1" style={{ textDecoration: 'underline' }}>+1 (833) 213-5678</a><br />
                      </Text>
                    });
                    onOpen();
                  }}
                >Contact Support</Button>
              </Flex>
            </Box>
            <Box p={5} bg={bg} color={"white"} boxShadow='xl' rounded='md'>
              <Flex alignItems="center" flexDirection="column">
                <FaBriefcase size="3em" />
                <Heading as="h4" size="md" mt={4}>Business Partnerships</Heading>
                <Text textAlign="center">Explore partnership opportunities.</Text>
                <Button mt={4} variant="solid" leftIcon={<FaBriefcase />}
                  onClick={() => {
                    setModalDetails({
                      title: 'Business Partnerships',
                      description: <Text>
                        Interested in partnering with us? We offer a variety of business solutions and partnership opportunities. Here are some ways to get in touch with us:<br /><br />
                        <br />
                        Email: <a href="mailto:omar@nesamobile.com?subject=Parternship with Nesa and [Your Company Here]" style={{ textDecoration: 'underline' }}>omar@nesamobile.com</a><br />
                        Calendly: <a target='_blank' href="https://calendly.com/nesamobile/discovery" style={{ textDecoration: 'underline' }}>Direct Meeting Link</a><br />
                      </Text>
                    });
                    onOpen();
                  }}
                >Partner with Us</Button>
              </Flex>
            </Box>
            <Box p={5} bg={bg} color={"white"} boxShadow='xl' rounded='md'>
              <Flex alignItems="center" flexDirection="column">
                <FaNewspaper size="3em" />
                <Heading as="h4" size="md" mt={4}>Media Inquiries</Heading>
                <Text textAlign="center">Press and media resources.</Text>
                <Button mt={4} variant="solid" leftIcon={<FaNewspaper />}
                  onClick={() => {
                    setModalDetails({
                      title: 'Media Inquiries',
                      description: <Text>
                        For all media inquiries, please contact our media team directly. Here are some ways to get in touch with us:<br /><br />
                        <br />
                        Email: <a href="mailto:omar@nesamobile.com?subject=Media Inquiry" style={{ textDecoration: 'underline' }}>omar@nesamobile.com</a><br />
                      </Text>
                    });
                    onOpen();
                  }}
                >Contact Media Team</Button>
              </Flex>
            </Box>
          </SimpleGrid>

        </Box>

      </Box>
    </Container>
  );
}

export default Contact;
