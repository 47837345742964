// src/components/HeroSection.jsx
import React from 'react';
import './HeroSection.css';

const HeroSection = ({ children }) => {
  return (
    <section className="hero-section">
      <div className="hero-shape">
        {children}
      </div>
    </section>
  );
};

export default HeroSection;
