import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
} from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import theme from './utils/brandTheme';

import ScrollToTop from './utils/scrollToTop';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="nesa-dev.us.auth0.com"
      clientId="chY3nCRGkWLLsESMnkLu2ycKj8KwGYoi"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://api.nesamobile.com/",
        scope: "openid profile email offline_access",
      }}
    >
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
