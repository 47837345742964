import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Image, Text, IconButton, Link } from '@chakra-ui/react';
import { IoIosArrowForward } from "react-icons/io";
import { Link as ReactRouterLink } from 'react-router-dom';
import CountryFlag from './CountryFlag';


const DestinationCard = ({ code, country }) => (
  <Box
    width={{ base: '100%', md: 'calc(25.33% - 20px)' }}
    bg="gray.800"
    borderRadius="md"
    overflow="hidden"
    boxShadow="md"
    margin="10px"
    p="4"
  >
    <Flex direction="row" align="center" justifyContent={"space-between"}>

      <Flex direction="row" alignItems={"center"} gap={3}>
        {/* <Image src={`/assets/images/flags/${code.toUpperCase()}.png`} fallback={<span>Unknown</span>} style={{ width: '32px', marginBottom: '8px' }} /> */}
        {/* <Flag code={code} fallback={<span>Unknown</span>} style={{ width: '32px', marginBottom: '8px' }} /> */}
        <CountryFlag isoCode={code} size={32} />
        <Text color="white" fontWeight="bold">{country}</Text>
        {/* <Text color="white" mb="2">{`Cost per GB: ${price}`}</Text> */}
      </Flex>
      <Link as={ReactRouterLink} to={`/plans/${code.toLowerCase()}`} style={{ textDecoration: 'none' }}>
        <IconButton
          icon={<IoIosArrowForward />}
          colorScheme="purple"
          // variant="outline"
          aria-label="Explore"
          isRound
        />
      </Link>
    </Flex>
  </Box>
);

DestinationCard.propTypes = {
  code: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

export default DestinationCard;
