import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Flex, Image, Card, Heading, Text, Stack, Grid, Button, useBreakpointValue, Tabs, TabPanel, TabPanels, TabList, Tab } from '@chakra-ui/react';

import CountryCard from './CountryCard';
import { RiArrowRightSLine } from "react-icons/ri";

import { Link as ReactRouterLink } from 'react-router-dom';

import CountryFlag from './CountryFlag.js';

import countryImagesMapping from './countryImagesMapping.js';

import ApiClient from '../../utils/API/api';
import countryUtils from '../../utils/Plans/country';
import { filterAndSortPackages } from '../../utils/Plans/plans';

import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
} from '@chakra-ui/react'

const Country = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [plans, setPlans] = useState([]);
	const { countryCode } = useParams();
	const navigate = useNavigate();
	const [countryPlans, setCountryPlans] = useState([]);
	const isMobile = useBreakpointValue({ base: true, md: false });
	const countryImageData = countryImagesMapping.filter(
		(country) => country.country === countryUtils.getName(countryCode.toUpperCase())
	)[0] ?? countryImagesMapping[0];

	const [segments, setSegments] = useState([
		{ label: "Reloadable", selected: false, shown: false },
		{ label: "Non-reloadable", selected: false, shown: false },
		{ label: "Unlimited", selected: false, shown: false },
	]);

	useEffect(() => {
		const fetchData = async () => {
			const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => { return ""; });
			try {
				const response = await apiClient.get('/plans');
				setPlans(response.data);
				console.log(response.data);
				const countrySpecificPlans = response.data.obj.packageList.filter((plan) => (
					plan.location === countryCode.toUpperCase()));
				setCountryPlans(filterAndSortPackages(countrySpecificPlans, countryCode.toUpperCase()));

				// See if we need to enable the segments:
				countrySpecificPlans.forEach((plan) => {
					if (!plan.slug.endsWith('_Daily') && !plan.packageCode.endsWith('_End')) {
						setSegments((prev) => {
							const newSegments = [...prev];
							newSegments[0].shown = true;
							return newSegments;
						});
					}
					if (plan.slug.endsWith('_End')) {
						setSegments((prev) => {
							const newSegments = [...prev];
							newSegments[1].shown = true;
							return newSegments;
						});
					}
					if (plan.slug.endsWith('_Daily')) {
						setSegments((prev) => {
							const newSegments = [...prev];
							newSegments[2].shown = true;
							return newSegments;
						});
					}
				});

				setIsLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [countryCode]);

	const filterPlans = (type) => {
		console.log(countryPlans.filter(plan => plan.slug.endsWith('_Daily')))
		switch (type) {
			case 'local':
				return countryPlans.filter(plan => plan.location === countryCode.toUpperCase());
			case 'regional':
				return countryPlans.filter(plan => plan.location !== countryCode.toUpperCase() && !plan.slug.startsWith('GL'));
			case 'global':
				return countryPlans.filter(plan => plan.slug.startsWith('GL'));
			case 'Reloadable':
				return countryPlans.filter(plan => !plan.slug.endsWith('_Daily') && !plan.slug.endsWith('_End'));
			case 'Non-reloadable':
				return countryPlans.filter(plan => plan.slug.endsWith('_End'));
			case 'Unlimited':
				return countryPlans.filter(plan => plan.slug.endsWith('_Daily'));
			default:
				return countryPlans
		}
	}

	const renderPlans = (plansArray) => (
		plansArray.length > 0 ? plansArray.map((plan) => (
			<CountryCard key={plan.packageCode} plan={plan} />
		)) : (
			<Text>No plans available</Text>
		)
	);

	if (isLoading) {
		return (
			<Box py="20" px="10">
				<Flex direction="column" align="center" maxW="1200px" mx="auto">
					<Heading size="xl" mb="10">Loading...</Heading>
				</Flex>
			</Box>
		);
	}

	return (
		<Box py="5" px="10">
			<Flex display={"flex"} alignItems={"flex-start"} gap={5} mb={10} maxW="1200px" mx="auto">
				<Breadcrumb spacing='8px' separator={<RiArrowRightSLine color='gray.500' />}>
					<BreadcrumbItem>
						<BreadcrumbLink as={ReactRouterLink} to='/plans'>Plans</BreadcrumbLink>
					</BreadcrumbItem>

					<BreadcrumbItem isCurrentPage>
						<BreadcrumbLink href='#' flex={1} direction={"column"}>
							{countryUtils.getName(countryCode.toUpperCase())}
						</BreadcrumbLink>
					</BreadcrumbItem>
				</Breadcrumb>
			</Flex>

			<Flex direction='row' gap="8" maxW="1200px" mx="auto" my="5">
				{!isMobile && (
					<Box pos="relative" boxSize={"35%"}>
						<Image boxSize='100%' src={countryImageData.fileName} alt={countryUtils.getName(countryCode.toUpperCase())} borderRadius='2xl' />
						<Box pos="absolute" backgroundColor={"rgba(0, 0, 0, 0.8)"} width={"100%"} p="3" bottom="0%" borderBottomRadius={"2xl"}>
							<Text bottom="2%" fontSize={12} left="2%" color="white">Photo by <a style={{ textDecoration: 'underline', color: 'white' }} target='_blank' href={countryImageData.photographerProfile}>{countryImageData.photographerName}</a> from <a style={{ textDecoration: 'underline', color: 'white' }} target='_blank' href={countryImageData.unsplashLink}>Unsplash</a></Text>
						</Box>
					</Box>
				)}
				<Box w={isMobile ? '100%' : "65%"}>
					<Flex justify={"flex-start"} alignItems={"center"} gap={5}>
						<CountryFlag isoCode={countryCode} size={32} />
						<Heading size="lg">Stay Connected in {countryUtils.getName(countryCode.toUpperCase())}</Heading>
					</Flex>
					<Text fontWeight={"500"} mt={2} mb={8}>Enjoy seamless and affordable internet access during your stay.</Text>
					<Stack spacing="8" w="100%">
						<Tabs variant='soft-rounded' colorScheme='purple'>
							<TabList>
								{segments.filter(segment => segment.shown).map((segment) => (
									<Tab key={segment.label}>{segment.label}</Tab>
								))}
							</TabList>
							<TabPanels>
								{segments.filter(segment => segment.shown).map((segment) => (
									<TabPanel key={segment.label}>
										<Grid templateColumns="repeat(auto-fit, minmax(200px, 1fr))" gap={6}>{renderPlans(filterPlans(segment.label))}</Grid>
									</TabPanel>
								))}
							</TabPanels>
						</Tabs>
					</Stack>
				</Box>
			</Flex>


		</Box>
	);
};

export default Country;
