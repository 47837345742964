import React from 'react';
import { Container, Heading, Text, VStack, Link, List, ListItem, ListIcon } from '@chakra-ui/react';
import { MdCheckCircle } from 'react-icons/md';

function PrivacyPolicy() {
  return (
    <Container maxW="container.md" py={5}>
      <VStack spacing={4} align="start">
        <Text>
          Nesa Mobile is committed to protecting your privacy. This Privacy Policy outlines the personal information we collect, how it is used, and how it is protected. By registering for an account or using Nesa Mobile services, you agree to the collection and use of information in accordance with this policy.
        </Text>

        <Heading as="h2" size="lg">1. What Information Do We Collect?</Heading>
        <List spacing={2}>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="green.500" />
            <strong>Personally Identifiable Information:</strong> We collect information such as your name, email address, phone number, and billing address when you sign up for our services, respond to a survey, or interact with our customer service.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="green.500" />
            <strong>Sensitive Personal Data:</strong> We do not intentionally collect sensitive personal data. If such data is inadvertently received, we do not store it and take steps to erase it promptly.
          </ListItem>
          <ListItem>
            <ListIcon as={MdCheckCircle} color="green.500" />
            <strong>Non-Personally Identifiable Information:</strong> We collect data such as your IP address, browser type, and service use patterns to improve our services and personalize your experience.
          </ListItem>
        </List>

        <Heading as="h2" size="lg">2. How We Use Your Information</Heading>
        <Text>
          The information collected is used to operate and maintain our services, manage your account, communicate with you, and enhance your user experience. With your consent, we may also send you promotional materials and newsletters.
        </Text>

        <Heading as="h2" size="lg">3. Disclosure of Your Information</Heading>
        <Text>
          We do not sell or rent your personal information. It may be shared with third-party service providers to assist in providing our services with appropriate confidentiality agreements. We may also disclose your information where required by law or to protect our rights.
        </Text>

        <Heading as="h2" size="lg">4. Children's Privacy</Heading>
        <Text>
          Our services are not directed to individuals under 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected such information, we will take steps to delete it.
        </Text>

        <Heading as="h2" size="lg">5. International Data Transfers</Heading>
        <Text>
          Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
        </Text>

        <Heading as="h2" size="lg">6. Your Rights and Choices</Heading>
        <Text>
          You have the right to access, correct, or delete the personal information we hold about you. You can manage your information via your account settings or by contacting us directly.
        </Text>

        <Heading as="h2" size="lg">7. Security Measures</Heading>
        <Text>
          We implement industry-standard security measures to protect your information. However, no security system is impenetrable, and we cannot guarantee the absolute security of our databases.
        </Text>

        <Heading as="h2" size="lg">8. Changes to This Policy</Heading>
        <Text>
          We reserve the right to update this privacy policy at any time. Changes will be effective immediately upon posting the revised policy. We will notify you of significant changes through our website or through direct communication.
        </Text>

      </VStack>
    </Container>
  );
}

export default PrivacyPolicy;
