// src/components/Header.js
import React from 'react';
import { Box, Flex, Center } from '@chakra-ui/react';
import Navbar from './Navbar';

// get the router and which page we are on:
import { useLocation } from 'react-router-dom';

function Header() {

    // which page we are on:
    const location = useLocation();

    console.log(location)

    return (
        <>
            <Center><Navbar /></Center>
            {location.pathname !== '/' && <Box h="100px" />}
        </>
    );
}

export default Header;
