import { border, extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = extendTheme({
	colors: {
		brand: {
			100: "#e6d6eb", // lighter purple
			500: "#521262", // main brand color
			700: "#2e0a37" // darker color for links or emphasis
		}
	},
	styles: {
		global: (props) => ({
			body: {
				bg: mode("#f7f7f7", "#333333")(props), // Light mode grey, dark mode darker grey
				color: mode("gray.800", "whiteAlpha.900")(props),
			},
			a: {
				color: mode("brand.700", "brand.100")(props), // Use darker purple in light mode and lighter in dark mode
				_hover: {
					// textDecoration: "underline",
				},
			}
		})
	},
	components: {
		Button: {
			baseStyle: (props) => ({
				fontWeight: 'bold', // Example of setting a default style for all buttons
			}),
			variants: {
				solid: (props) => ({
					bg: mode("brand.500", "brand.700")(props),
					color: 'white',
					_hover: {
						bg: mode("brand.700", "brand.500")(props)
					},
				}),
				outline: (props) => ({
					borderColor: "brand.500",
					color: mode("brand.500", "brand.700")(props),
					_hover: {
						bg: mode("brand.100", "gray.600")(props)
					},
				}),
				ghost: (props) => ({
					color: mode("brand.500", "brand.100")(props),
					bg: mode("brand.300", "brand.100")(props), // light opaque purple
					_hover: {
						bg: mode("brand.100", "brand.300")(props) // slightly darker on hover
					},
					borderRadius: 'full',
				}),
				link: (props) => ({
					color: mode("brand.700", "brand.100")(props),
					bg: "transparent",
					_hover: {
						textDecoration: "underline",
						bg: "transparent",
					},
					_active: {
						bg: "transparent",
					}
				})
			}
		}
	}
});

export default theme;