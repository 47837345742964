import React from 'react';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

function Error() {
  // Grab the parameters from the URL using react router dom
  const [searchParams] = useSearchParams();
  const allParams = {};
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    allParams[param] = value;
  }

  return (
    <>
      <style>
        {`
          @keyframes bounce {
            0%, 100% { transform: translateY(0); }
            50% { transform: translateY(-20px); }
          }
          .bouncing {
            animation: bounce 5s infinite;
          }
        `}
      </style>

      <Box p={5} w="full" rounded="md" textAlign={'center'}>
        <Center>
          <Image
            src={allParams.type === 'auth' ? '/assets/images/robot-thinking.png' : '/assets/images/robot-repair.png'}
            alt={`Vee is ${allParams.type === 'auth' ? 'thinking...' : 'confused'}`}
            maxH={250}
            className="bouncing" // Apply the bouncing class here
          />
        </Center>
        <Text fontSize="xl" fontWeight={"bold"} mt={5}>Oh no...</Text>
        {
          allParams.type === 'auth' ? (
            <>
              <Text fontSize="lg" mt={5}>It seems like you had an issue with authenticating.</Text>
              <Text fontSize="lg" mt={5}>{allParams.error_description}</Text>
            </>
          ) : (
            <>
              <Text fontSize="lg" mt={5}>The page you are looking for does not exist.</Text>
              <Text fontSize="lg" mt={5}>Please check the URL and try again. If the issue persists, please give us a minute while we fix it!</Text>
            </>
          )
        }
      </Box>
    </>
  );
}

export default Error;
