import React, { useState } from 'react';
import {
	Box,
	Flex,
	Button,
	useColorModeValue,
	Container,
	Link,
	Image,
	IconButton,
	Drawer,
	DrawerBody,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	useDisclosure,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import { RxHamburgerMenu } from "react-icons/rx";
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom';

const Navbar = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [placement, setPlacement] = useState('right');
	const btnRef = React.useRef();
	const navigate = useNavigate();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const linkColor = useColorModeValue("#2e0a37", "#2e0a37");
	const bgColor = useColorModeValue('rgba(255, 255, 255, 0.95)', 'rgba(26, 32, 44, 0.95)'); // Slight transparency for floating effect
	const boxShadow = useColorModeValue('sm', 'sm-dark'); // Optional: different shadow for light/dark modes

	return (
		<>
			<Box
				position="fixed" // Fixes the navbar to the viewport
				top="2%" // Positions it at the top
				left="auto"
				width="80%" // Makes it span the full width
				bg={bgColor} // Semi-transparent background
				px={4}
				borderRadius={isMobile ? "0" : "full"} // Rounded bottom corners for non-mobile
				boxShadow={boxShadow} // Adds shadow for depth
				zIndex="1000" // Ensures it stays above other elements
			>
				<Container maxW="container.xl">
					<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
						<Box
							display={'flex'}
							flexDir={"row"}
							alignItems={"center"}
							onClick={() => navigate("/")}
							cursor="pointer" // Indicates clickable area
						>
							<Image src="/assets/images/logo.png" alt="Nesa Mobile" h="50px" />
							<Text fontWeight={"bold"} color={"#2e0a37"} _hover={{ cursor: "pointer" }}>
								Nesa
							</Text>
						</Box>

						<Flex alignItems={'center'}>
							<IconButton
								ref={btnRef}
								icon={<RxHamburgerMenu />}
								display={{ base: 'flex', md: 'none' }}
								onClick={onOpen}
								size="md"
								aria-label={'Open Menu'}
								fontSize="20px"
								variant="ghost"
								color={linkColor}
							/>
							<Box display={{ base: 'none', md: 'flex' }} ml={10}>
								<Button
									variant='ghost'
									as={ReactRouterLink}
									to='/'
									color={linkColor}
									_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
								>
									Home
								</Button>
								<Button
									variant='ghost'
									as={ReactRouterLink}
									to='/plans'
									color={linkColor}
									_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
								>
									Destinations
								</Button>
								{/* <Button
									variant='ghost'
									as={ReactRouterLink}
									to='/faq'
									color={linkColor}
									_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
								>
									How It Works
								</Button> */}
								{/* <Button
								variant='ghost'
								as={ReactRouterLink}
								to='/about'
								color={linkColor}
								_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
							>
								About
							</Button> */}
								<Button
									variant='ghost'
									as={ReactRouterLink}
									to='/contact'
									color={linkColor}
									_hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
								>
									Support
								</Button>
							</Box>
						</Flex>
					</Flex>
				</Container>

				<Drawer
					isOpen={isOpen}
					placement={placement}
					onClose={onClose}
					finalFocusRef={btnRef}
				>
					<DrawerOverlay />
					<DrawerContent>
						<DrawerCloseButton />
						<DrawerHeader>Menu</DrawerHeader>

						<DrawerBody>
							<Link
								as={ReactRouterLink}
								to="/"
								p={2}
								display="block"
								color={linkColor}
								fontWeight="bold"
								onClick={onClose}
							>
								Home
							</Link>
							<Link
								as={ReactRouterLink}
								to="/plans"
								p={2}
								display="block"
								color={linkColor}
								fontWeight="bold"
								onClick={onClose}
							>
								Destinations
							</Link>
							{/* <Link
								as={ReactRouterLink}
								to="/faq"
								p={2}
								display="block"
								color={linkColor}
								fontWeight="bold"
								onClick={onClose}
							>
								How It Works
							</Link> */}
							{/* <Link
							as={ReactRouterLink}
							to="/about"
							p={2}
							display="block"
							color={linkColor}
							fontWeight="bold"
							onClick={onClose}
						>
							About
						</Link> */}
							<Link
								as={ReactRouterLink}
								to="/contact"
								p={2}
								display="block"
								color={linkColor}
								fontWeight="bold"
								onClick={onClose}
							>
								Support
							</Link>
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</Box>
		</>
	);
};

export default Navbar;
