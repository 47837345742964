import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CountryMapperISO3To2 from '../../utils/CountryMapperISO3To2';

// URL to TopoJSON data
const geoUrl =
  'https://raw.githubusercontent.com/subyfly/topojson/refs/heads/master/world-countries.json';

const MapContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

function InteractiveMap() {
  const navigate = useNavigate();

  const handleCountryClick = (geo) => {
    const countryId = CountryMapperISO3To2(geo.id) ?? 'XX'; // Adjust based on GeoJSON properties
    navigate(`/plans/${countryId}`);
  };

  return (
    <MapContainer>
      <ComposableMap
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 147,
        }}
        width={800}
        height={400}
        style={{ width: '100%', height: 'auto' }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onClick={() => handleCountryClick(geo)}
                style={{
                  default: {
                    fill: '#D6D6DA',
                    outline: 'none',
                  },
                  hover: {
                    fill: '#F53',
                    outline: 'none',
                    cursor: 'pointer',
                  },
                  pressed: {
                    fill: '#E42',
                    outline: 'none',
                  },
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </MapContainer>
  );
}

export default InteractiveMap;
