import React from 'react';
import { Box, Input, InputGroup, InputRightElement, Icon } from '@chakra-ui/react';
import { FaSearch } from "react-icons/fa";

const SearchBar = ({ placeholder, width, showIcon, setSearchQuery }) => (

  <Box position="relative" width={width}>
    <InputGroup>
      <Input
        placeholder={placeholder}
        size="lg"
        borderRadius="full"
        boxShadow="md"
        paddingLeft="1.5rem"
        bg="white"
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      {showIcon && (
        <InputRightElement width="4.5rem" pt={"2"}>
          <Icon as={FaSearch} color="gray.500" />
        </InputRightElement>
      )}
    </InputGroup>
  </Box>

);

export default SearchBar;
