import React from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Container,
  Flex,
  Heading,
  VStack,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Icon
} from '@chakra-ui/react';
import { RiArrowGoBackLine } from "react-icons/ri";

import Error from './Error';

import { useNavigate } from 'react-router-dom'


const faqData = require('../utils/FAQs.json');

function QuestionDetails() {
  let { questionId } = useParams();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();

  const question = faqData.sections
    .flatMap(section => section.questions)
    .find(q => q.id === questionId);

  if (!question) {
    return <Error />;
  }

  return (
    <Container maxW="container.lg" p={4} minH={"100vh"}>
      <Box flex="1" bg="white" p={5} borderRadius="md" borderWidth="1px">
        <Flex display={"flex"} alignItems={"center"} gap={5} mb={10}>
          {!isMobile && (<Button onClick={() => navigate('/faq')} value={'link'} leftIcon={<RiArrowGoBackLine />} variant='ghost'>Back</Button>)}
          <Heading size="lg">{question.question}</Heading>
        </Flex>
        <VStack align="start" spacing={4} mt={4}>
          {/* show the question and answer */}
          <Box>{question.answer}</Box>
        </VStack>
      </Box>

    </Container>
  );
}

export default QuestionDetails;