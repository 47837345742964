// src/components/BackgroundShape.jsx
import React from 'react';
import './BackgroundShape.css';

const BackgroundShape = ({ children, type }) => {
  return (
    <div className={`background-shape ${type}`}>
      {children}
    </div>
  );
};

export default BackgroundShape;
