import React, { useState } from 'react';
import {
	Box,
	Center,
	Text,
	Stack,
	Link,
	Grid,
	GridItem,
	Icon,
	Image,
	Flex,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
	Button,
	useBreakpointValue
} from '@chakra-ui/react';
import CurrencySelector from './Footer/CurrencySelector';

import { FaInstagram, FaWhatsapp, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { Link as ReactRouterLink } from 'react-router-dom';
import TermsOfService from './Footer/terms';
import PrivacyPolicy from './Footer/privacy';

import countryUtils from '../utils/Plans/country';
import CountryFlag from './Plans/CountryFlag';

import Intercom from '@intercom/messenger-js-sdk';

const Footer = () => {

	Intercom({
		app_id: 'a2h4r9jl',
	});

	const isMobile = useBreakpointValue({ base: true, md: false });
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [modalDetails, setModalDetails] = useState({
		title: '',
		description: <Text>Hello world</Text>,
		size: 'md'
	});

	const countries = ["US", "FR", "DE", "IT", "ES", "TR",];
	const destinations = countryUtils.shuffleArray(countryUtils.getCountriesFromArray(countries)); // Assuming shuffle is a function that shuffles the array


	const [currentCurrency, setCurrentCurrency] = useState(() => {
		const storedCurrency = localStorage.getItem('currency') || 'USD';
		return storedCurrency;
	});

	return (
		<Box bg="gray.800" color="white" py={4} px={8}>
			<Modal isOpen={isOpen} onClose={onClose} size={modalDetails.size}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{modalDetails.title}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{modalDetails.description}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="purple" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Center>
				<Grid
					templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
					gap={6}
					maxW="1200px"
					width="full"
				>
					<GridItem colSpan={{ base: 1, md: 1 }}>
						<Flex gap={3} alignItems={"center"} >
							<Image src="/assets/images/logo_transparent.png" alt="Nesa Mobile" h="50px" />
							<Text fontSize="lg" fontWeight="bold">Nesa Mobile</Text>
						</Flex>
						<Text mt="5">
							At Nesa Mobile, we connect travelers with the world effortlessly. Our mission is to provide reliable, affordable eSIM solutions for both individual and business travelers.
						</Text>
					</GridItem>
					<GridItem colSpan={{ base: 1, md: 3 }} colStart={{ md: 2 }}>
						<Stack direction={{ base: "column", md: "row" }} spacing={10} justifyContent="flex-end">
							<Stack>
								<Text fontWeight="bold" mb={1}>POPULAR DESTINATIONS</Text>
								{destinations.map((destination) => {
									return (
										<Flex gap={3}>
											<CountryFlag isoCode={destination.code} size={20} />
											<Link as={ReactRouterLink} to={`/plans/${destination.code}`}>{destination.name}</Link>
										</Flex>
									);
								})}
							</Stack>
							<Stack>
								<Text fontWeight="bold" mb={1}>COMPANY</Text>
								<Link as={ReactRouterLink} to="/about">About Us</Link>
								<Link as={ReactRouterLink} to="/contact">Contact Us</Link>
								{/* <Link href="/blog">Blog</Link> */}
							</Stack>
							{/* <Stack>
								<Text fontWeight="bold" mb={1}>RESOURCES</Text>
								<Link as={ReactRouterLink} to="/documentation">Documentation</Link>
								<Link as={ReactRouterLink} to="/papers">Papers</Link>
								<Link as={ReactRouterLink} to="/press">Press Conferences</Link>
							</Stack> */}
							<Stack>
								<Text fontWeight="bold" mb={1}>LEGAL</Text>
								<Link variant="link"
									onClick={() => {
										setModalDetails({
											title: 'Terms of Service',
											description: <TermsOfService />,
											size: 'full'
										});
										onOpen();
									}}
								>Terms of Service</Link>
								<Link variant="link"
									onClick={() => {
										setModalDetails({
											title: 'Privacy Policy',
											description: <PrivacyPolicy />,
											size: 'full'
										});
										onOpen();
									}}
								>Privacy Policy</Link>
								{/* <Link as={ReactRouterLink} to="/cookies">Cookies Policy</Link> */}
							</Stack>
						</Stack>
					</GridItem>
				</Grid>
			</Center>

			<Center>
				<Flex justify={isMobile ? "flex-start" : "flex-end"} maxW={"1200px"} width={"100%"} textAlign={"right"}>

					<Link variant="link" m={5} onClick={() => {

						setModalDetails({
							title: 'Change Language',
							description: <Text>Sorry, this feature is coming soon!</Text>,
							size: 'md'
						});
						onOpen();
					}}>Language: English</Link>

					<Link variant="link" m={5} onClick={() => {

						setModalDetails({
							title: 'Change Currency',
							description: <CurrencySelector />,
							size: 'md'
						});
						onOpen();
					}}>Currency: {currentCurrency}</Link>
				</Flex>
			</Center>

			<Text textAlign="center" mt={4} borderTopWidth={1} pt={2} borderColor="gray.700">
				© 2024 Nesa eSIM. All rights reserved.
			</Text>

			<Stack direction="row" spacing={4} justifyContent="center" mt={2}>
				<Link href="https://x.com/nesamobile" isExternal>
					<Icon as={FaXTwitter} w={8} h={8} />
				</Link>
				<Link href="https://instagram.com/nesamobilecom" isExternal>
					<Icon as={FaInstagram} w={8} h={8} />
				</Link>
				<Link href="https://wa.me/message/Z6CYYQEBRG5XM1" isExternal>
					<Icon as={FaWhatsapp} w={8} h={8} />
				</Link>
				<Link href="https://www.linkedin.com/company/nesamobile" isExternal>
					<Icon as={FaLinkedin} w={8} h={8} />
				</Link>
			</Stack>
		</Box>
	);
};

export default Footer;
